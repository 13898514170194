<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";
@import 'assets/sass/custom';
// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import { getLocalStorageInfo, isFutureTimestamp } from './functions';

export default {
  name: 'eventbox',
/*metaInfo() {
    const title = 'eventbox';
    const description = 'Explore the world with eventbox';
    const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/eventbox-c7c8f.appspot.com/o/ForWeb%2FLogoForMeta.png?alt=media&token=774ae500-7463-447e-be05-75b792288275';
    const siteUrl = 'https://eventboxde.com';
    return {
      title: title,
      meta: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: siteUrl },
        { property: 'og:site_name', content: 'eventbox' },
        { property: 'og:image', itemprop: 'image primaryImageOfPage', content: imageUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:domain', content: 'eventboxde.com' },
        { name: 'twitter:title', property:'og:title', itemprop: 'name', content: title },
        { name: 'twitter:description', property:'og:description', itemprop:'description', content: description },
        { name: 'twitter:image', content: imageUrl }
      ]
    }
  },*/
  mounted() {
    const myId = localStorage.getItem('eventboxMyId');
    if (myId) {
      this.$store.commit('set', ['myId', myId]);
      const myInfo = getLocalStorageInfo('eventboxMyInfo');
      if (myInfo) {
        this.$store.commit('set', ['myInfo', myInfo]);
      }
    }
    const eventList = getLocalStorageInfo('eventboxEventList');
    if (eventList) {
      this.$store.commit('set', ['eventList', eventList]);
      const liveEventList = eventList.filter(element => isFutureTimestamp(element.endAt));
      this.$store.commit('set', ['liveEventList', liveEventList]);
    }
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
