import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as de } from "@/core/config/i18n/de";
import { locale as en } from "@/core/config/i18n/en";

Vue.use(VueI18n);

let messages = { de, en };

// get current selected language
const lang = localStorage.getItem('eventboxLanguage') || 'de';

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

export default i18n;
